<template>
    <div class="d-flex align-items-baseline">
      <input :id="name" type="checkbox" value="1" :name="name" @change.stop.prevent="onCheckboxChange"/>
      <label :for="name">
        <slot/>
        <span v-if="error" class="error-checkbox">{{error}}</span>
      </label>
    </div>
</template>

<script>
  export default {
    name: "CheckboxComponent",
    props:{
      name: {
        type: String,
        required: true
      },
      error: {
        type: String
      }
    },
    methods:{
      onCheckboxChange(e){
        this.$emit('checkbox:change', e)
      }
    }
  }
</script>

<style scoped>
input[type=checkbox] + label > p{
  margin-bottom: 0;
}
.error-checkbox{
  width: 100%;
  color: var(--theme-error);
  font-size: .8rem;
  font-weight: 400;
}
</style>