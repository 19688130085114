<template>
	<div>
		<checkbox-component :name="name" @checkbox:change="onChange">
			<p>
				{{ $t('Receive news', {name: channelName}) }}
			</p>
		</checkbox-component>
	</div>
</template>

<script>
  import CheckboxComponent from "./CheckboxComponent";
  import {mapState} from "vuex";
  export default {
    name: "NewsCheckbox",
    components: {CheckboxComponent},
    props:{
      name: {
        type: String,
        default: 'news'
      }
    },
    computed:{
      ...mapState({
        channelSettings: state => state.channel.settings,
      }),
      channelName(){
        return this.channelSettings?.title

      }
    },
    methods:{
      onChange(e){
        this.$emit('checkbox:change', {
          name: this.name,
          value: e.target.checked
        })
      }
    }
  }
</script>

<style scoped>
	p{
		margin-left: 1rem;
	}

</style>